<template>
  <div class="swap">
    <Header>兑换盲盒</Header>
    <div class="container">
      <div class="content">
        <div class="list">
          <div class="listItem" v-for="item in list" :key="item.goodsId">
            <div class="left">
              <img :src="item.listPic" alt="" />
            </div>
            <div class="middle">
              <div class="name">{{ item.name }}</div>
              <div class="subTitle">最多可兑换数量：{{ item.total }}</div>
              <div class="num">
                <div class="muns child" @click="muns">-</div>
                <div class="input">
                  <input
                    type="text"
                    @input="inputChange(item.total)"
                    v-model.number="num"
                  />
                </div>
                <div class="plus child" @click="plus(item.total)">+</div>
              </div>
            </div>
            <div class="right">
              <div class="btn" @click="swap(item)">立即兑换</div>
            </div>
          </div>
          <div class="def" v-if="!list.length">
            <img src="../../../static/image/hezi2.png" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>

    <div class="tips" v-if="showTip">
      <div class="box">
        <div class="title">温馨提示</div>
        <div class="info">兑换为盲盒后将不可寄卖，是否继续？</div>
        <div class="btn">
          <div class="left" @click="showTip = false">取消</div>
          <div class="right" @click="submit">继续</div>
        </div>
      </div>
    </div>
    <div class="tips" v-if="showTip1">
      <div class="box">
        <div class="title">提示</div>
        <div class="info">盲盒兑换成功</div>
        <div class="btn">
          <div class="right" style="margin: 0 auto" @click="showTip1 = false">
            确定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      num: 0,
      showTip: false,
      showTip1: false,
      info: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    swap(item) {
      if (this.num <= 0) {
        return this.$toast("请输入数量");
      }
      this.showTip = true;
      this.info = item;
    },
    submit() {
      this.showTip = false;
      this.$api
        .blindBoxRedemptionVoucher({
          goodsId: this.info.goodsId,
          num: this.num,
          token: localStorage.getItem("token"),
        })
        .then((e) => {
          if (e.code == 0) {
            this.getList();
            this.showTip1 = true;
          } else {
            this.$toast(e.msg);
          }
        });
    },
    muns() {
      if (this.num <= 0) return;
      this.num--;
    },
    plus(total) {
      if (this.num >= total) return;
      this.num++;
    },
    inputChange(total) {
      if (this.num >= total) {
        this.$nextTick(() => {
          this.num = total;
        });
      } else if (this.num <= 0) {
        this.$nextTick(() => {
          this.num = 0;
        });
      }
    },
    getList() {
      this.$api
        .blindBoxRedemptionVoucherList({ token: localStorage.getItem("token") })
        .then((e) => {
          if (e.code === 0) {
            this.list = e.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.swap {
  background: #f3f4f8;
  min-height: 100vh;
  .container {
    .content {
      .list {
        padding: 15px;
        .listItem {
          padding: 15px 10px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          background: #fff;
          .left {
            img {
              width: 85px;
              height: 85px;
              vertical-align: middle;
            }
          }
          .middle {
            flex: 1;
            margin-left: 10px;
            .name {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #000000;
            }
            .subTitle {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              margin: 10px 0 13px;
            }
            .num {
              display: flex;
              align-items: center;
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              .child {
                border: 1px solid #ececec;
                border-radius: 3px;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.muns {
                  border-right: none;
                }
                &.plus {
                  border-left: none;
                }
              }
              input {
                border: 1px solid #ececec;
                outline: none;
                height: 25px;
                width: 40px;
                color: #666666;
                text-align: center;
              }
            }
          }
          .right {
            .btn {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
              width: 73px;
              height: 28px;
              background: linear-gradient(-89deg, #3a6bff 0%, #0754d3 100%);
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .tips {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
      padding: 20px;
      box-sizing: border-box;
      width: 282px;
      height: 170px;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 15px;
        text-align: center;
        font-weight: 600;
        color: #000;
      }
      .info {
        font-size: 14px;
        color: #666;
        text-align: center;
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          width: 80px;
          height: 28px;
          background: #0754d3;
          border-radius: 3px;
          border: 1px solid #0754d3;
          color: #fff;
          &.left {
            color: #0754d3;
            border: 1px solid #0754d3;
            background: transparent;
          }
        }
      }
    }
  }
}
.def {
  text-align: center;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
</style>